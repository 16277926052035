import {
  Button,
  Circle,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  LinkBox,
  LinkOverlay,
  Spacer,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import VianikoLogo from "../assets/logos/logo.svg";

import { useCurrentUser } from "../providers/CurrentUserProvider";
import {
  authUrl,
  eventNewUrl,
  homeUrl,
  userShowUrl,
} from "../services/routes/urlBuilder";
import { useFriendshipRequests } from "../pages/events/hooks/useFriendshipRequests";
import { BiCalendar, BiHome, BiPlus, BiUserPlus } from "react-icons/bi";
import { UserAvatar } from "./UserAvatar";
import { ButtonLink } from "./ButtonLink";
import { useOrganizationUser } from "../providers/CurrentOrganizationUserProvider";
import {
  hasPermission,
  PERMISSION_MANAGE_EVENTS,
} from "../services/permissions";

export const AppHeader: React.FC = () => {
  const { currentUser } = useCurrentUser();
  const location = useLocation();

  const { friendshipRequestIncomingByUserId } = useFriendshipRequests();
  const numFriendshipRequests = Object.keys(
    friendshipRequestIncomingByUserId || {}
  ).length;

  const displayLink = useBreakpointValue({ base: false, sm: true });
  const navIconSize = useBreakpointValue({ base: "30px", sm: "18px" });
  const navIconContainerSize = useBreakpointValue({ base: "40px", sm: "18px" });
  const createEventText = useBreakpointValue({
    base: "Event",
    md: "Create event",
  });

  const { organizationId } = useParams();
  const { organizationUser } = useOrganizationUser(organizationId);
  const showCreateEventButton =
    hasPermission(organizationUser?.permissions, PERMISSION_MANAGE_EVENTS) ||
    organizationUser?.is_owner;

  return (
    <HStack height="100%">
      <Link as={RouterLink} to={homeUrl()}>
        <Image src={VianikoLogo} height="40px" marginTop={2} />
      </Link>

      <Spacer />

      <HStack spacing={displayLink ? 3 : 0} height="100%">
        {displayLink && (
          <Link as={RouterLink} to={homeUrl()}>
            <Flex direction={"row"} gap={1} alignItems="center" marginTop={2}>
              <Flex
                boxSize={navIconContainerSize}
                alignItems="center"
                justifyContent="center"
              >
                <BiHome fontSize={navIconSize} />
              </Flex>
              <Text>Home</Text>
            </Flex>
          </Link>
        )}

        {currentUser && displayLink && (
          <Link
            as={RouterLink}
            to={userShowUrl(currentUser.id, {
              tab: "events",
              eventsDirection: "future",
            })}
          >
            <Flex direction={"row"} gap={1} alignItems="center" marginTop={2}>
              <Flex
                boxSize={navIconContainerSize}
                alignItems="center"
                justifyContent="center"
              >
                <BiCalendar fontSize={navIconSize} />
              </Flex>
              <Text>Events</Text>
            </Flex>
          </Link>
        )}

        {showCreateEventButton && (
          <Button
            as={RouterLink}
            to={eventNewUrl({ organizationId })}
            variant="primary"
            marginTop={2}
          >
            <Flex direction={"row"} gap={1} alignItems="center">
              <BiPlus fontSize={"18px"} />
              <Text>{createEventText}</Text>
            </Flex>
          </Button>
        )}
      </HStack>

      {currentUser ? (
        <LinkBox display="flex" marginTop={2}>
          {numFriendshipRequests > 0 && (
            <Circle backgroundColor="red.500" size="40px" marginX={1}>
              <Icon as={BiUserPlus} fontSize="18px" color="white" />
            </Circle>
          )}
          <LinkOverlay
            as={RouterLink}
            to={userShowUrl(currentUser.id)}
            alignItems="center"
          >
            <UserAvatar size="md" user={currentUser} />
          </LinkOverlay>
        </LinkBox>
      ) : (
        <>
          {!location.pathname.startsWith("/auth") && (
            <ButtonLink
              to={authUrl(homeUrl())}
              buttonProps={{ variant: "primary" }}
            >
              Sign in
            </ButtonLink>
          )}
        </>
      )}
    </HStack>
  );
};
