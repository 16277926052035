import React from "react";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { Link } from "react-router-dom";

interface AppFooterButtonProps {
  icon: IconType;
  label: string;
  to?: string;
}

export const AppFooterButton: React.FC<AppFooterButtonProps> = ({
  icon,
  label,
  to,
}) => {
  return (
    <Flex as={Link} to={to} direction="column" alignItems="center">
      <Icon as={icon} />
      <Text>{label}</Text>
    </Flex>
  );
};
