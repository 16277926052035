import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { VianikoEvent } from "../../../types/events";
import { TiTicket } from "react-icons/ti";
import { TicketTypesSection } from "./forms/TicketTypesSection";
import { ButtonLink } from "../../../components/ButtonLink";
import {
  ticketTypeEditUrl,
  ticketTypeNewUrl,
} from "../../../services/routes/urlBuilder";
import { Panel } from "../../../components/Panel";
import { useLocation, useNavigate } from "react-router-dom";
import { TicketType } from "../../../types/ticket_types";
import { eventLevelTicketsUnvailable } from "../services";
import { Ticket } from "../../../types/ticket";
import { useTicketTypesAdmin } from "../../../hooks/useTicketTypesAdmin";
import { Organization } from "../../../types/organization";
import { QrCode } from "../checkin/QrCode";
import { OrganizationSetupPaymentsBadge } from "../../payments/components/OrganizationSetupPaymentsBadge";
import { useState } from "react";
import { EditEventPassesModal } from "./forms/EditEventPassesModal";
import { usePasses } from "../../passes/hooks/usePasses";
import { BiDotsHorizontalRounded, BiTrashAlt } from "react-icons/bi";
import { CancelTicketConfirmationModal } from "./CancelTicketConfirmationModal";
import { refundTicket } from "../../../services/api/tickets";

interface EventTicketsProps {
  event: VianikoEvent;
  organization?: Organization;
  tickets: Ticket[];
  isAdmin: boolean;
  onBuyTickets: () => void;
}

export const EventTickets: React.FC<EventTicketsProps> = ({
  event,
  organization,
  tickets,
  isAdmin,
  onBuyTickets,
}) => {
  const [ticketToRefund, setTicketToRefund] = useState<Ticket | null>(null);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { ticketTypes } = useTicketTypesAdmin(event.id);
  const { passes } = usePasses(event.organization_id);

  const [isPassesModalOpen, setIsPassesModalOpen] = useState(false);

  const handleEditTicketType = (ticketType: TicketType) => {
    navigate(ticketTypeEditUrl(event.id, ticketType.id));
  };

  const ticketsByType = tickets.reduce((acc, ticket) => {
    const ticketType = ticket.ticket_type_id;
    if (!acc[ticketType]) {
      acc[ticketType] = [];
    }
    acc[ticketType].push(ticket);
    return acc;
  }, {} as Record<string, Ticket[]>);

  const handleChangePasses = () => {
    setIsPassesModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsPassesModalOpen(false);
    navigate(pathname, { replace: true });
  };

  const handleRefundTicket = async (ticket: Ticket) => {
    await refundTicket(ticket);
    setTicketToRefund(null);
    window.location.reload();
  };

  const handleRefundModal = (ticket: Ticket) => () => {
    setTicketToRefund(ticket);
  };

  return (
    <>
      <EditEventPassesModal
        isOpen={isPassesModalOpen}
        onClose={handleCloseModal}
        event={event}
        passes={passes}
      />

      {ticketToRefund && (
        <CancelTicketConfirmationModal
          event={event}
          ticket={ticketToRefund}
          isOpen={!!ticketToRefund}
          onClose={() => setTicketToRefund(null)}
          onConfirm={handleRefundTicket}
        />
      )}

      <Panel>
        <VStack gap={4} alignItems="stretch">
          {isAdmin && (
            <Box width="100%">
              <HStack>
                <Heading size="md" width="100%">
                  Tickets
                </Heading>
                <Spacer />
                {organization && (
                  <OrganizationSetupPaymentsBadge organization={organization} />
                )}
              </HStack>

              <TicketTypesSection
                ticketTypes={ticketTypes}
                currencyIsoCode={event.currency_iso_code}
                onEdit={handleEditTicketType}
                isAdmin={isAdmin}
              />
            </Box>
          )}

          {isAdmin ? (
            <Flex width="100%" gap={2} marginBottom={4}>
              <>
                <ButtonLink
                  to={ticketTypeNewUrl(event.id)}
                  buttonProps={{
                    variant: "primary",
                    size: "lg",
                    width: "100%",
                  }}
                  flexGrow={6}
                >
                  Create ticket
                </ButtonLink>

                <Button
                  variant="outline"
                  size="lg"
                  onClick={onBuyTickets}
                  flexGrow={1}
                >
                  Buy ticket
                </Button>
              </>
            </Flex>
          ) : !eventLevelTicketsUnvailable(event) ? (
            <Button
              variant="primary"
              size="lg"
              onClick={onBuyTickets}
              flexGrow={6}
            >
              Buy ticket
            </Button>
          ) : null}

          {isAdmin && (
            <>
              <Heading size="md">
                Passes available for this event
                {!!event.recurring_event_id ? " series" : ""}
              </Heading>

              {event?.passes && event.passes.length > 0 && (
                <Box>
                  {event.passes.map((pass) => (
                    <Text key={pass.id} size="md">
                      {pass.name}
                    </Text>
                  ))}
                </Box>
              )}

              <Button variant="primary" size="lg" onClick={handleChangePasses}>
                Change passes
              </Button>
            </>
          )}

          {Object.entries(ticketsByType).length > 0 && (
            <Box width="100%" marginTop={4}>
              <Heading size="md">Your tickets</Heading>

              <VStack>
                {Object.entries(ticketsByType).map(
                  ([ticketTypeId, tickets]) => (
                    <Box key={ticketTypeId} width="100%">
                      {tickets.map((ticket, index) => (
                        <Box key={ticket.id} width="100%">
                          <HStack>
                            <Icon as={TiTicket} />
                            <Text size="md">
                              {ticket.name} - {index + 1} of {tickets.length}
                            </Text>
                            <Spacer />
                            <Menu>
                              <MenuButton
                                as={IconButton}
                                aria-label="Options"
                                icon={<Icon as={BiDotsHorizontalRounded} />}
                                variant="ghost"
                              />
                              <MenuList>
                                <MenuItem onClick={handleRefundModal(ticket)}>
                                  <Icon as={BiTrashAlt} marginRight={2} />
                                  <Text size="md">Cancel</Text>
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </HStack>

                          <QrCode value={ticket.id} />
                        </Box>
                      ))}
                    </Box>
                  )
                )}
              </VStack>
            </Box>
          )}
        </VStack>
      </Panel>
    </>
  );
};
