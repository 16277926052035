import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "./AuthProvider";
import * as api from "../services/api";
import { EventUser } from "../types/event_users";
import { useLocation, useNavigate, matchPath } from "react-router-dom";
import { eventShowUrl } from "../services/routes/urlBuilder";
import { useEvent } from "../hooks/useEvent";
import { useOrganization } from "./CurrentOrganizationProvider";
import { useOrganizationUser } from "./CurrentOrganizationUserProvider";
import {
  hasPermission,
  PERMISSION_MANAGE_EVENTS,
} from "../services/permissions";

interface EventUserContext {
  currentEventUser: EventUser | undefined;
  refetch: () => void;
}

const CurrentEventUserContext = createContext<EventUserContext>({
  currentEventUser: undefined,
  refetch: () => {},
});

interface CurrentEventUserProviderProps {
  eventId: string;
  children: React.ReactNode;
}

export const CurrentEventUserProvider = ({
  eventId,
  children,
}: CurrentEventUserProviderProps) => {
  const { user: authUser, initialized: authUserInitialized } = useAuth();
  const [eventUser, setEventUser] = useState<EventUser | undefined>(undefined);
  const [eventUserInitialized, setEventUserInitialized] = useState(false);
  const { event } = useEvent(eventId);
  const { organization } = useOrganization(event?.organization_id);
  const { organizationUser, initialized: organizationUserInitialized } =
    useOrganizationUser(organization?.id);

  const location = useLocation();
  const navigate = useNavigate();

  const fetchEventUser = useCallback(async () => {
    if (authUserInitialized) {
      console.log("authUserInitialized", authUserInitialized);
      console.log("authUser", authUser);
      if (authUser) {
        try {
          const nextEventUser = await api.eventUsers.fetchCurrentEventUser(
            eventId
          );
          console.log("nextEventUser", nextEventUser);
          setEventUser(nextEventUser);
        } catch (error) {
          setEventUser(undefined);
        }
      } else {
        setEventUser(undefined);
      }

      setEventUserInitialized(true);
    }
  }, [authUserInitialized, authUser, eventId]);

  useEffect(() => {
    fetchEventUser();
  }, [fetchEventUser]);

  useEffect(() => {
    console.log("eventUserInitialized", eventUserInitialized);
    console.log("eventUser", eventUser);
    debugger;
    if (!eventUserInitialized || !organizationUserInitialized) return;

    const isEventPage = matchPath(`/events/${eventId}/*`, location.pathname);

    const hasOrgAccess =
      organizationUser?.is_owner ||
      hasPermission(organizationUser?.permissions, PERMISSION_MANAGE_EVENTS);

    if (isEventPage) {
      if (!eventUser && !hasOrgAccess) {
        navigate(eventShowUrl(eventId), { replace: true });
      }
    }
  }, [
    eventUser,
    eventId,
    location.pathname,
    navigate,
    eventUserInitialized,
    organizationUserInitialized,
    organizationUser?.is_owner,
    organizationUser?.permissions,
  ]);

  const refetch = useCallback(() => {
    fetchEventUser();
  }, [fetchEventUser]);

  return (
    <CurrentEventUserContext.Provider
      value={{ currentEventUser: eventUser, refetch }}
    >
      {children}
    </CurrentEventUserContext.Provider>
  );
};

export const useCurrentEventUser = (): EventUserContext => {
  return useContext(CurrentEventUserContext);
};
