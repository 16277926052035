import { useCallback, useEffect, useState } from "react";
import { Ticket } from "../../../types/ticket";
import { getTicketPricePaid } from "../../../services/api/tickets";

interface UseTicketPricePaid {
  price: number | undefined;
}

export const useTicketPricePaid = (ticket: Ticket): UseTicketPricePaid => {
  const [price, setPrice] = useState(undefined);

  const fetch = useCallback(async () => {
    const { price } = (await getTicketPricePaid(ticket)) || {};
    setPrice(price || undefined);
  }, [ticket]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { price };
};
