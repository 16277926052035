import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { Panel } from "../../../components/Panel";
import { useForm } from "react-hook-form";
import { Organization } from "../../../types/organization";
import { useCurrentOrganization } from "../../../providers/CurrentOrganizationProvider";
import { NumberField } from "../../../components/forms/NumberField";
import { patchOrganization } from "../../../services/api/organizations";
import { useNavigate } from "react-router-dom";
import { organizationShowUrl } from "../../../services/routes/urlBuilder";

export const ManageRefunds: React.FC = () => {
  const { organization } = useCurrentOrganization();
  const { register, handleSubmit, watch, setValue } = useForm<Organization>({
    mode: "onBlur",
    defaultValues: organization,
  });
  const navigate = useNavigate();

  const isRefundable = watch("is_refundable");
  const numDaysRefundableAhead = watch("num_days_refundable_ahead");

  const handleChangeNumDays = (value: number) => {
    setValue("num_days_refundable_ahead", value);
  };

  const handleChangeLastDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue("num_days_refundable_ahead", e.target.checked ? 1 : 0);
  };

  const onSubmit = async (data: Organization) => {
    const { is_refundable, num_days_refundable_ahead } = data;
    if (!organization) return;
    await patchOrganization(organization.id, {
      is_refundable,
      num_days_refundable_ahead,
    });

    navigate(organizationShowUrl(organization.id));
  };

  return (
    <Panel>
      <VStack alignItems="stretch">
        <Heading size="md" marginBottom={2}>
          Refunds
        </Heading>

        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack>
            <FormControl>
              <HStack>
                <Checkbox {...register("is_refundable")} />
                <FormLabel margin={0}>allow refunds</FormLabel>
              </HStack>
            </FormControl>

            {isRefundable && (
              <>
                <FormControl>
                  <HStack>
                    <Checkbox
                      isChecked={numDaysRefundableAhead > 0}
                      onChange={handleChangeLastDay}
                    />
                    <FormLabel margin={0}>last day for refunds</FormLabel>
                  </HStack>
                </FormControl>

                {numDaysRefundableAhead > 0 && (
                  <FormControl>
                    <HStack>
                      <NumberField
                        value={numDaysRefundableAhead}
                        onChange={handleChangeNumDays}
                      />
                      <FormLabel margin={0}>
                        days before the start of each event
                      </FormLabel>
                    </HStack>
                  </FormControl>
                )}
              </>
            )}

            <Button type="submit" variant="primary" size="lg" width="100%">
              Save
            </Button>
          </VStack>
        </form>
      </VStack>
    </Panel>
  );
};
