import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "./AuthProvider";
import * as api from "../services/api";
import { OrganizationUser } from "../types/organization_users";

interface OrganizationUserContext {
  currentOrganizationUser: OrganizationUser | undefined;
  refetch: () => void;
  initialized: boolean;
}

const CurrentOrganizationUserContext = createContext<OrganizationUserContext>({
  currentOrganizationUser: undefined,
  refetch: () => {},
  initialized: false,
});

interface CurrentOrganizationUserProviderProps {
  organizationId: string;
  children: React.ReactNode;
}

export const useOrganizationUser = (organizationId: string | undefined) => {
  const { user: authUser, initialized: authInitialized } = useAuth();
  const [initialized, setInitialized] = useState(false);
  const [organizationUser, setOrganizationUser] = useState<
    OrganizationUser | undefined
  >(undefined);

  const fetchOrganizationUser = useCallback(async () => {
    if (authUser && organizationId) {
      const user = await api.organizationUsers.fetchCurrentOrganizationUser(
        organizationId
      );
      setOrganizationUser(user);
    } else {
      setOrganizationUser(undefined);
    }

    if (authInitialized) {
      setInitialized(true);
    }
  }, [authInitialized, authUser, organizationId]);

  useEffect(() => {
    fetchOrganizationUser();
  }, [fetchOrganizationUser]);

  return { organizationUser, refetch: fetchOrganizationUser, initialized };
};

export const CurrentOrganizationUserProvider = ({
  organizationId,
  children,
}: CurrentOrganizationUserProviderProps) => {
  const { organizationUser, refetch, initialized } =
    useOrganizationUser(organizationId);

  return (
    <CurrentOrganizationUserContext.Provider
      value={{
        currentOrganizationUser: organizationUser,
        refetch,
        initialized,
      }}
    >
      {children}
    </CurrentOrganizationUserContext.Provider>
  );
};

export const useCurrentOrganizationUser = (): OrganizationUserContext => {
  return useContext(CurrentOrganizationUserContext);
};
