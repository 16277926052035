import { Flex, Spacer } from "@chakra-ui/react";
import { BiCalendar, BiHome, BiUser } from "react-icons/bi";
import { homeUrl, userShowUrl } from "../services/routes/urlBuilder";
import { VianikoUser } from "../types/users";
import { AppFooterButton } from "./AppFooterButton";

interface AppFooterProps {
  user: VianikoUser;
}

export const AppFooter: React.FC<AppFooterProps> = ({ user }) => {
  return (
    <Flex
      height="60px"
      flexShrink={0}
      backgroundColor="white"
      width="100%"
      justifyContent="space-between"
      px={4}
      py={2}
    >
      <AppFooterButton icon={BiHome} label="Home" to={homeUrl()} />
      <Spacer />
      <AppFooterButton
        icon={BiCalendar}
        label="Calendar"
        to={userShowUrl(user.id, {
          tab: "events",
          eventsDirection: "future",
        })}
      />
      <Spacer />
      <AppFooterButton
        icon={BiUser}
        label="Profile"
        to={userShowUrl(user.id)}
      />
    </Flex>
  );
};
