export const homeUrl = () => "/";
export const welcomeUrl = () => "/welcome";

interface AuthOptions {
  authReason?: "invite" | "ticket_purchase";
}

export const authUrl = (redirectTo?: string, options?: AuthOptions) => {
  const { authReason } = options || {};

  const urlParams = new URLSearchParams({
    redirect: redirectTo ? encodeURIComponent(redirectTo) : homeUrl(),
    authReason: authReason || "",
  });

  return `/auth?${urlParams.toString()}`;
};

interface UserShowUrlParams {
  tab: "events";
  eventsDirection: "future";
}
export const userShowUrl = (userId: string, params?: UserShowUrlParams) => {
  const { tab, eventsDirection } = params || {};
  const urlParams = params
    ? new URLSearchParams({
        tab: tab ? tab.toString() : "",
        eventsDirection: eventsDirection ? eventsDirection.toString() : "",
      })
    : "";

  return `/users/${userId}?${urlParams.toString()}`;
};

export const userEditUrl = (userId: string, redirectAfter?: string) =>
  `/users/${userId}/edit${
    redirectAfter ? `?redirectAfter=${encodeURIComponent(redirectAfter)}` : ""
  }`;

interface EventShowUrlParams {
  invite?: boolean;
  welcome?: boolean;
  ticket_selection?: boolean;
  ticket_types?: {
    [key: string]: number;
  };
}

export const eventShowUrl = (eventId: string, params?: EventShowUrlParams) => {
  const { invite, welcome, ticket_selection, ticket_types } = params || {};
  const paramsObject: { [key: string]: string } = {};

  if (invite) paramsObject["invite"] = invite.toString();
  if (welcome) paramsObject["welcome"] = welcome.toString();
  if (ticket_selection)
    paramsObject["ticket_selection"] = ticket_selection.toString();
  if (ticket_types)
    paramsObject["ticket_types"] = encodeURIComponent(
      JSON.stringify(ticket_types)
    );

  const urlParams =
    Object.keys(paramsObject).length > 0
      ? new URLSearchParams(paramsObject)
      : "";

  return `/events/${eventId}?${urlParams.toString()}`;
};

export const eventAttendeesUrl = (eventId: string) =>
  `/events/${eventId}/attendees`;
export const eventProgramUrl = (eventId: string) =>
  `/events/${eventId}/program`;
export const eventTicketsUrl = (eventId: string) =>
  `/events/${eventId}/tickets`;
export const eventMessagesUrl = (eventId: string) =>
  `/events/${eventId}/messages`;

export const eventEditUrl = (eventId: string) => `/events/${eventId}/edit`;
interface EventNewUrlParams {
  organizationId?: string;
  parentEventId?: string;
}
export const eventNewUrl = (params?: EventNewUrlParams) => {
  const { organizationId, parentEventId } = params || {};

  const urlParams = new URLSearchParams({
    parentEventId: parentEventId || "",
  });

  return `${
    organizationId ? `/organizations/${organizationId}` : ""
  }/events/new?${urlParams.toString()}`;
};

export const ticketTypeNewUrl = (eventId: string) => {
  return `/events/${eventId}/ticket_types/new`;
};

export const ticketTypeEditUrl = (eventId: string, ticketTypeId: string) => {
  return `/events/${eventId}/ticket_types/${ticketTypeId}/edit`;
};

interface EventPaymentUrlParams {
  amountInCents?: string;
  paymentToken?: string;
}
export const eventPaymentUrl = (
  eventId: string,
  params?: EventPaymentUrlParams
) =>
  `/events/${eventId}/payments/new${
    params
      ? `?amountInCents=${params.amountInCents}&paymentToken=${params.paymentToken}`
      : ""
  }`;

interface PaymentsRedirectorOptions {
  amountInCents?: string;
  paymentToken?: string;
}
export const paymentsRedirectorUrl = (
  eventId: string,
  options?: PaymentsRedirectorOptions
) => {
  const { amountInCents, paymentToken } = options || {};

  let paramsObject: { [key: string]: string } = {};

  if (amountInCents) paramsObject["amountInCents"] = amountInCents;
  if (paymentToken) paramsObject["paymentToken"] = paymentToken;

  return `/events/${eventId}/payments?${
    options ? new URLSearchParams(paramsObject).toString() : ""
  }`;
};

export const eventCheckinUrl = (eventId: string) =>
  `/events/${eventId}/checkin`;
export const eventScanUrl = (eventId: string) =>
  `/events/${eventId}/checkin/scan`;

export const recurringEventShowUrl = (recurringEventId: string) =>
  `/recurring_events/${recurringEventId}`;
export const recurringEventEditUrl = (recurringEventId: string) =>
  `/recurring_events/${recurringEventId}/edit`;
export const recurringEventNewUrl = (organizationId: string) =>
  `/organizations/${organizationId}/recurring_events/new`;

export const organizationShowUrl = (organizationId: string) =>
  `/organizations/${organizationId}`;
export const organizationInfoUrl = (organizationId: string) =>
  `/organizations/${organizationId}/info`;
export const organizationMembersUrl = (organizationId: string) =>
  `/organizations/${organizationId}/members`;
export const organizationPassesUrl = (organizationId: string) =>
  `/organizations/${organizationId}/passes`;
export const organizationWaiversUrl = (organizationId: string) =>
  `/organizations/${organizationId}/waivers`;

export const organizationEditUrl = (organizationId: string) =>
  `/organizations/${organizationId}/edit`;

export const waiverNewUrl = (organizationId: string) =>
  `/organizations/${organizationId}/waivers/new`;
export const waiverEditUrl = (organizationId: string, waiverId: string) =>
  `/organizations/${organizationId}/waivers/${waiverId}/edit`;
export const waiverShowUrl = (
  organizationId: string,
  waiverId: string,
  redirectTo?: string
) =>
  `/organizations/${organizationId}/waivers/${waiverId}${
    redirectTo ? `?redirectTo=${encodeURIComponent(redirectTo)}` : ""
  }`;
export const waiverSignAllUrl = (
  organizationId: string,
  { eventId }: { eventId?: string } = {}
) => {
  const urlParams = new URLSearchParams({
    eventId: eventId || "",
  });
  return `/organizations/${organizationId}/waivers/sign-all?${urlParams.toString()}`;
};

export const passNewUrl = (organizationId: string) =>
  `/organizations/${organizationId}/passes/new`;
export const passEditUrl = (organizationId: string, passId: string) =>
  `/organizations/${organizationId}/passes/${passId}/edit`;

export const passPaymentUrl = (passId: string, eventIdToEnroll?: string) =>
  `/passes/${passId}/payments/new${
    eventIdToEnroll ? `?eventIdToEnroll=${eventIdToEnroll}` : ""
  }`;
export const passPostPaymentUrl = (passId: string, eventIdToEnroll?: string) =>
  `/passes/${passId}/payments/post?eventIdToEnroll=${eventIdToEnroll}`;

export const eventPurchasePassUrl = (eventId: string, passId: string) =>
  `/events/${eventId}/passes/${passId}/purchase`;

export const adminManageOrganizationUsersUrl = (organizationId: string) =>
  `/admin/organizations/${organizationId}/manage_users`;
export const adminManageOrganizationPayoutUrl = (organizationId: string) =>
  `/admin/organizations/${organizationId}/manage-payout`;
export const adminManageOrganizationRefundsUrl = (organizationId: string) =>
  `/admin/organizations/${organizationId}/manage-refunds`;
