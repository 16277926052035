import { matchPath, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { authUrl } from "../services/routes/urlBuilder";
import { useEffect } from "react";

export const AuthenticatedRoutes: React.FC = () => {
  const { user, refreshing, initialized } = useAuth();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const isPublicPath = matchPath("/events/:eventId/*", pathname);

  useEffect(() => {
    if (initialized && !user && !refreshing && !isPublicPath) {
      navigate(authUrl(`${pathname}${search}`));
    }
  }, [initialized, user, refreshing, navigate, pathname, search, isPublicPath]);

  return <Outlet />;
};
