import { Ticket } from "../../types/ticket";
import axiosInstance from "./axiosInstance";

export const patchTicket = async (
  ticketId: string,
  ticket: Partial<Ticket>
) => {
  const result = await axiosInstance.patch(`/tickets/${ticketId}`, ticket);
  return result.data;
};

export const getTicketPricePaid = async (ticket: Ticket) => {
  const result = await axiosInstance.get(`/tickets/${ticket.id}/price-paid`);
  return result.data;
};

export const refundTicket = async (ticket: Ticket) => {
  const result = await axiosInstance.post(`/tickets/${ticket.id}/refund`);
  return result.data;
};
